import {ContactRender} from "./render/ContactRender.js";

const contactRender = new ContactRender();
contactRender.showEmailIfNeeded();

const mapButton = document.getElementById("init-map");
if(mapButton) {
  mapButton.addEventListener("click", () => {
    let map = document.getElementById("map");
    map.innerHTML = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1725.6447350081671!2d7.170467392607655!3d52.29004602057235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b83b19233b4feb%3A0x960734f38c5f4b82!2sKolonatstra%C3%9Fe%2013%2C%2048455%20Bad%20Bentheim!5e0!3m2!1sde!2sde!4v1708815415373!5m2!1sde!2sde" 
    width="100%" height="100%" 
    style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

  });
}
