import {appendElementInSpecificElement} from "../util/DomUtil.js";

export class ContactRender {
  showEmailIfNeeded() {
    const madd = document.getElementById("madd");
    if(madd) {
      const mail_text = document.createElement("span");
      mail_text.textContent = "E-Mail: ";
      appendElementInSpecificElement(mail_text, madd);

      const mail = document.createElement("a");
      mail.href = "mailto:katrin.diefenbach@gmx.de";
      mail.textContent = "katrin.diefenbach@gmx.de";
      appendElementInSpecificElement(mail, madd);
    }
  }
}
