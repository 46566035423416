export function prependPrefixToStringIfNeeded(string, prefix) {
  if(string.startsWith(prefix)) {
    return string;
  }

  return prefix + string;
}

export function elementReady(selector) {
  return new Promise(function(resolve, reject) {
    var el = document.querySelector(selector);
    if (el) {resolve(el);}
    new MutationObserver(function(mutationRecords, observer) {
      Array.from(document.querySelectorAll(selector)).forEach(function(element) {
        resolve(element);
        observer.disconnect();
      });
    })
      .observe(document.documentElement, {
        childList: true,
        subtree: true
      });
  });
}

export function elementIsHidden(selector) {
  return new Promise(function(resolve, reject) {
    var el = document.querySelector(selector);
    if (el.style.display !== "none" && el.style.visibility !== "hidden" &&
      (el.style.opacity === "" || el.style.opacity > 0))
    {
      resolve(true);
    }
    new MutationObserver(function(mutationRecords, observer) {
      Array.from(document.querySelectorAll(selector)).forEach(function(element) {
        resolve(element);
        observer.disconnect();
      });
    })
      .observe(document.documentElement, {
        childList: true,
        subtree: true
      });
  });
}

export function elementHasClass(element, class_to_find) {
  var has_class = false;
  if(element) {
    has_class = element.classList.contains(class_to_find);
  }

  return has_class;
}

export function toggleElementClass(element, class_to_toggle) {
  if(!element) {
    return true;
  }

  if(elementHasClass(element, class_to_toggle)) {
    removeClassFromElement(element, class_to_toggle);
  } else {
    addClassToElement(element, class_to_toggle);
  }
}

export function findAllElementsWithClass(class_to_find) {
  class_to_find = prependPrefixToStringIfNeeded(class_to_find, ".");
  return document.querySelectorAll(class_to_find);
}

export function findAllVisibleElementsWithClass(class_to_find) {
  class_to_find = prependPrefixToStringIfNeeded(class_to_find, ".");
  return filterAllVisibleElementsWithClassInElement(document, class_to_find);
}

export function elementIsVisible(elem) {
  // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
  return !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
}

export function hideOnClickOutside(not_to_be_clicked_element_id, element_to_hide, class_to_add, class_to_remove, callback) {
  var outsideClickListener = (event) => {
    if (event.target.closest("#" + not_to_be_clicked_element_id) === null && elementIsVisible(element_to_hide)) {
      if(!class_to_add && !class_to_remove) {
        class_to_add = "hidden";
      }

      if(class_to_add) {
        addClassToElementIfNotAssigned(element_to_hide, class_to_add);
      } else if(class_to_remove) {
        removeClassFromElementIfAssigned(element_to_hide, class_to_remove);
      }

      element_to_hide.setAttribute("aria-expanded", "false");
      removeClickListener();
      if(callback) {
        callback();
      }
    }
  }

  var removeClickListener = function() {
    document.removeEventListener('click', outsideClickListener);
  }

  document.addEventListener('click', outsideClickListener);
}

export function findAllVisibleElementsWithClassInParent(parent_element, class_to_find) {
  class_to_find = prependPrefixToStringIfNeeded(class_to_find, ".");
  return filterAllVisibleElementsWithClassInElement(parent_element, class_to_find);
}

export function filterAllVisibleElementsWithClassInElement(element, class_to_find) {
  return findAllVisibleElementsInParentViaQuery(element, class_to_find);
}

export function findAllVisibleElementsInParentViaQuery(element, query_selector_all) {
  return Array.prototype.slice.call(
    element.querySelectorAll(query_selector_all))
    .filter(
      function (item,index) {
        var styles = window.getComputedStyle(item);
        return styles.getPropertyValue("display") !== "none" &&
          styles.getPropertyValue("visibility") !== "hidden" &&
          styles.getPropertyValue("opacity") > 0
      }
    );
}

export function findAllElementsInParentViaQuery(element, query_selector_all) {
  return element.querySelectorAll(query_selector_all);
}

export function findAllVisibleElementsWithNameInParent(parent_element, name_to_find) {
  return Array.prototype.slice.call(
    parent_element.getElementsByName(name_to_find))
    .filter(
      function (item,index) {
        var styles = window.getComputedStyle(item);
        return styles.getPropertyValue("display") !== "none" &&
          styles.getPropertyValue("visibility") !== "hidden" &&
          styles.getPropertyValue("opacity") > 0
      }
    );
}

export function findVisibleElementWithClass(class_to_find) {
  class_to_find = prependPrefixToStringIfNeeded(class_to_find, ".");
  var element = document.querySelector(class_to_find);
  if(element === null) {
    return null;
  }

  var styles = window.getComputedStyle(element);
  if(styles.getPropertyValue("display") !== "none" &&
    styles.getPropertyValue("visibility") !== "hidden" &&
    styles.getPropertyValue("opacity") > 0) {
    return element;
  } else {
    return null;
  }
}

export function removeClassFromElement(element, class_to_remove) {
  if(element) {
    element.classList.remove(class_to_remove);
  }
}

export function addClassToElement(element, class_to_add) {
  if(element) {
    element.classList.add(class_to_add);
  }
}

export function addClassToElementIfNotAssigned(element, class_to_add) {
  if(!elementHasClass(element, class_to_add)) {
    addClassToElement(element, class_to_add);
  }
}

export function removeClassFromElementIfAssigned(element, class_to_remove) {
  if(elementHasClass(element, class_to_remove)) {
    removeClassFromElement(element, class_to_remove);
  }
}

export function loadElementWidthInPixelAsInteger(element) {
  var width = -1;
  if(element) {
    width = parseInt(getComputedStyle(element).width.replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("padding-left").replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("padding-right").replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("margin-left").replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("margin-right").replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("border-left-width").replace("px", ""));
    width += parseInt(getComputedStyle(element).getPropertyValue("border-right-width").replace("px", ""));
  }
  return width;
}

export function loadElementHeightInPixelAsInteger(element) {
  var height = -1;
  if(element) {
    height = getComputedStyle(element).height.replace("px", "");
    height += parseInt(getComputedStyle(element).getPropertyValue("padding-top").replace("px", ""));
    height += parseInt(getComputedStyle(element).getPropertyValue("padding-bottom").replace("px", ""));
    height += parseInt(getComputedStyle(element).getPropertyValue("margin-top").replace("px", ""));
    height += parseInt(getComputedStyle(element).getPropertyValue("margin-bottom").replace("px", ""));
    height += parseInt(getComputedStyle(element).getPropertyValue("border-top-width").replace("px", ""));
    height += parseInt(getComputedStyle(element).getPropertyValue("border-bottom-width").replace("px", ""));
  }
  return height;
}

export function findAllElementsWithClassAndRemoveTheClass (element_class) {
  var elements = findAllVisibleElementsWithClass(element_class);
  if(elements.length === 0) {
    return true;
  }

  for(var i = 0; i < elements.length; i++) {
    var elem = elements[i];
    removeClassFromElement(elem, element_class);
  }
}

export function findAllElementsWithClassInParentAndRemoveTheClass (parent_element, element_class) {
  var elements = findAllVisibleElementsWithClassInParent(parent_element, element_class);
  if(elements.length === 0) {
    return true;
  }

  for(var i = 0; i < elements.length; i++) {
    var elem = elements[i];
    removeClassFromElement(elem, element_class);
  }
}

export function removeElementByIdIfExists(element_id) {
  var elem = document.getElementById(element_id);
  if(elem) {
    elem.remove();
  }
}

export function findParentWithClass(element, parent_class) {
  parent_class = prependPrefixToStringIfNeeded(parent_class, ".");
  return element.closest(parent_class);
}

export function addElementAfterSpecificElement(element_to_add, element_after_which_to_add) {
  element_after_which_to_add.insertAdjacentElement("afterend", element_to_add);
}

export function addElementBeforeSpecificElement(element_to_add, element_after_which_to_add) {
  element_after_which_to_add.insertAdjacentElement("beforebegin", element_to_add);
}

export function appendElementInSpecificElement(element_to_add, parent) {
  if(!element_to_add || !parent) {
    return true;
  }
  parent.append(element_to_add);
}

export function prependElementInSpecificElement(element_to_add, parent) {
  if(!element_to_add || !parent) {
    return true;
  }
  parent.prepend(element_to_add);
}

export function appendHtmlInSpecificElement(html_to_add, parent_element) {
  parent_element.insertAdjacentHTML("beforeend", html_to_add);
}

export function prependHtmlInSpecificElement(html_to_add, parent_element) {
  parent_element.insertAdjacentHTML("afterbegin", html_to_add);
}

export function addOrUpdateAttributeToElement(element, attribute, value) {
  if(!element) {
    return true;
  }

  element.setAttribute(attribute, value);
}

export function removeAttributeFromElement(element, attribute) {
  if(!element) {
    return true;
  }

  element.removeAttribute(attribute);
}

export function getAllFollowingSiblingsBySelector(elem, selector) {
  let silblings = [];
  let sibling = elem.nextElementSibling;

  if (!selector) return silblings;

  while (sibling) {
    if (sibling.matches(selector)) silblings.push(sibling);
    sibling = sibling.nextElementSibling
  }

  return silblings;
}

export function getAllPreviousSiblingsBySelector(elem, selector) {
  let silblings = [];
  var sibling = elem.previousElementSibling;

  if (!selector) return silblings;

  while (sibling) {
    if (sibling.matches(selector))  silblings.push(sibling);
    sibling = sibling.previousElementSibling;
  }

  return silblings;
}
